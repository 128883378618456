.home__hero-section {
  color: #1b1d33;
}

.home__hero-row {
  display: flex;
  align-content: stretch;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row-reverse;
}

.col {
  padding: 0 20px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

input [type="text"] {
  width: 70%;
  border: none;
  outline: none;
  border-radius: 50px 0 0 50px;
}

button {
  border-radius: 50px;
  height: 60px;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  color: #fff;
}

.space-btn {
  margin-right: 20px;
}

.home__hero-text-wrapper {
  width: 100%;
}

.none {
  display: none;
}

.top-line {
  color: #14706c;
  font-weight: 800;
  margin: 30px auto;
  margin-bottom: 30px;
  width: 100%;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.sub-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  color: #005457;
  width: 384px;
  margin-bottom: 30px;
}

.small-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #14706c;
  margin-bottom: 30px;
}

.small-heading span {
  width: 50px;
}

.reg-list h3 {
  font-weight: 800;
  color: #198a84;
  margin-bottom: -30px;
}

h5 {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin: 20px 0 10px 0;
}

.vect-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(165, 166, 246, 0.32);
  border-radius: 8px;
  margin-bottom: 10px;
}

.heading {
  margin-top: 40px;
  margin-bottom: 24px;
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
  color: #fff;
  width: 540px;
}

.greenhead {
  color: #27d6cd;
}

.dark {
  max-width: 450px;
  color: #fff;
}

.light {
  color: #252525;
}

.whiteBg {
  padding-top: 100px;
  padding-bottom: 100px;
}

.greyBg {
  color: #252525;
  background-color: #eff6f6;
  padding-top: 150px;
}

.greyBg h2 {
  width: 100%;
  color: #14706c;
}

.darkBg {
  color: #fff;
  background-color: #198a84;
  padding-bottom: 100px;
  background-image: url("../components/asset/background.svg");
}

.darkerBg {
  color: #fff;
  background-color: #0d4a47;
}

.about-bottom{
  padding: 0 !important;
  margin: 0 !important;
}

.home__hero-subtitle {
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 32px;
}

.space-bottom{
  margin-bottom: 40px;
}

.desc {
  max-width: 528px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 45px;
  text-align: center;
}

.home__hero-img-wrapper {
  max-width: 555px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  margin: 0 60px;
}

.home__hero-img {
  object-fit: cover;
  margin: 0px 0 0 40px;
  border-radius: 30px;
}

.main_hero-img {
  position: relative;
  width: 100%;
}

.home__hero-img.main {
  object-fit: cover;
  width: 226.24px;
  height: 226px;
}

.sub_hero-img {
  position: relative;
  width: 100%;
}

.home__hero-img.sub {
  object-fit: cover;
  position: absolute;
  width: 212.8px;
  height: 206px;
  top: 130px;
  left: 30px;
}

.solo-container {
  position: relative;
  width: 100%;
}

.home__hero-img.solo {
  object-fit: cover;
  width: 389px;
}

.extra-text {
  font-size: 12px;
  font-weight: 400;
  background-color: #5aaaa5;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  width: 200px;
  position: absolute;
  left: 0;
  bottom: -30px;
  padding: 8px 16px;
}

.extra-text-middle {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 10px 30px;
  background-color: #eff6f6;
  right: -30px;
  bottom: -30px;
  width: 253px;
  height: 65px;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.05),
    0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.extra-text-bottom .text-one {
  position: absolute;
  width: 214px;
  height: 65px;
  left: -30px;
  top: -30px;
  background: #198a84;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #eff6f6;
  padding: 10px 30px;
  line-height: 22px;
  font-size: 14px;
}

.extra-text-bottom .text-two {
  position: absolute;
  width: 224px;
  height: 65px;
  right: -50px;
  bottom: -30px;
  background: #dfebea;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #14706c;
  padding: 10px 30px;
  line-height: 22px;
  font-size: 14px;
}

.floats {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 180px;
  bottom: -100px;
}

.home__hero-floated-img {
  object-fit: cover;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  border: 2px solid #fff;
  margin-left: -10px;
}

@media (min-width: 0) and (max-width: 992px) {
  .dark {
    max-width: 100%;
  }
}

@media (max-width: 768px) and (min-width: 550px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .home__hero-section {
    width: 100%;
  }

  .row {
    margin: 0 -15px -15px -15px;
  }
  .home__img-embed {
    width: 90%;
    padding-right: 20%;
    height: 100px;
  }

  .top-line {
    text-align: center;
  }

  .form-container {
    width: 90%;
    font-size: 18px;
  }

  .sub-heading {
    font-size: 14px;
    line-height: 30px;
    color: #198a84;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  .home__hero-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .home__hero-section {
    width: 100%;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;
  }

  h5 {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 10px 0 5px 0;
  }

  .row {
    flex-direction: column-reverse;
  }

  #pricing {
    margin-top: -150px;
  }

  .home__hero-img-wrapper {
    margin: 20px 0 50px;
  }
  .solo-container {
    width: 90%;
    margin-bottom: 20px;
  }

  .home__hero-text-wrapper {
    margin: 0 auto;
    width: 100%;
  }

  .top-line {
    width: 271px;
    height: 50px;
    font-size: 22px;
    line-height: 35px;
    margin-top: -50px;
  }

  .heading {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
  }

  h3 {
    margin-top: 30px;
  }

  .sub-heading {
    width: 381px;
    height: 128px;
    font-weight: 800;
    font-size: 42px;
    line-height: 64px;
  }

  .darkBg {
    padding-bottom: 50px;
  }

  .greyBg {
    padding-top: 80px;
  }

  .greyBg .top-line {
    height: auto;
    margin-bottom: 20px;
  }

  .greyBg .home__hero-img-wrapper {
    margin-top: -2px;
  }

  .greyBg h2 {
    width: 380px;
    font-weight: 800;
    font-size: 42px;
    line-height: 64px;
    margin-bottom: 100px;
  }

  .home__hero-img {
    margin: 15px;
    height: auto;
  }

  .home-btn-wrapper {
    padding-bottom: 80px;
  }

  .home__hero-subtitle {
    font-size: 18px;
    margin: 0;
    padding-bottom: 20px;
  }

  .space-bottom{
    margin-bottom: 30px;
  }

  .list {
    height: 1px;
  }

  .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .home__sub-photo {
    margin: 30px auto 30px 5px;
  }

  .home__hero-img-wrapper {
    max-width: 555px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    margin: 100px 0 50px -15px;
  }

  .main_hero-img {
    position: relative;
    width: 100%;
  }

  .home__hero-img.main {
    object-fit: cover;
    width: 295.51px;
    height: 203.84px;
  }

  .sub_hero-img {
    position: relative;
    width: 100%;
  }

  .home__hero-img.sub {
    object-fit: cover;
    position: absolute;
    width: 175.44px;
    height: 175.44px;
    top: 90px;
    left: 10px;
  }

  .floats {
    position: absolute;
    right: 150px;
    bottom: -50px;
    width: 121px;
    height: 52px;
  }

  .extra-text {
    font-size: 8px;
    width: 243px;
    height: auto;
    left: 0;
    bottom: 5px;
    padding: 8px;
  }

  .home__hero-floated-img {
    object-fit: cover;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 2px solid #fff;
    margin-left: -10px;
  }

  .home__img-embed {
    width: 90%;
    margin-left: -3px;
    height: 75px;
    padding-right: 100px;
    transition: all 0.5s ease-in-out;
  }

  .home__img-embed.red {
    width: 90%;
    height: 140px;
    background: none;
    color: rgba(220, 57, 58, 0.35);
    box-shadow: none;
  }

  .home__img-embed.grey {
    width: 100%;
    height: 200px;
    left: 35px;
    top: -251px;
    border: none;
    background: none;
    color: rgba(134, 132, 132, 0.39);
    box-shadow: none;
  }

  .home__img-embed:hover {
    transform: rotate(5deg);
  }

  .home__sub-photo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .home__sub-photo img {
    margin: 0;
  }

  .list li {
    font-size: 14px;
    color: #198a84;
    font-weight: 500;
    line-height: 20px;
    background-size: 10px;
    padding: 30px 0 10px 28px;
  }

  form {
    width: 100%;
  }

  input {
    font-size: 12px;
    width: 70%;
    line-height: 140%;
    text-align: left;
    flex: 4;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
    padding: 0px 5px;
  }
}

@media (min-width: 0) and (max-width: 600px) {

  .space-btn {
    margin-bottom: 100px;
    margin-right: 0;
  }

  .hero .white {
    margin-bottom: 20px;
    margin-right: 0;
  }

  button {
    width: 100%;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  .whiteBg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (min-width: 0) and (max-width: 350px) {
  .home__img-embed {
    width: 90%;
    margin-left: 3px;
    height: 75px;
    padding-right: 100px;
    transition: all 0.5s ease-in-out;
  }

  .home__img-embed:hover {
    transform: rotate(5deg);
  }

  .top-line {
    text-align: center;
  }

  input[type="text"] {
    font-size: 15px;
    text-align: start;
    display: none;
    width: 100%;
  }

  input[type="submit"] {
    position: relative;
    left: 0%;
    border-radius: 50px;
    width: 200px;
    height: 60px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
