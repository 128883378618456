.reg-logo {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
}

.reg-content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #dfebea;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../components/asset/forms-bg-lines.png");
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 90px;
}

.reg-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.reg-content > div {
  width: 48%;
}

.reg-content h3 {
  color: #0d4a47;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  padding: 20px 0;
}

.reg-content span {
  color: #1b9690;
}

.left {
  padding-right: 5%;
}

#reg-para {
  width: 466px;
  font-weight: 800;
  font-size: 18px;
  line-height: 32px;
  color: #252525;
  margin-bottom: 0;
}

.left img {
  width: 352px;
  height: 217px;
  border-radius: 20px;
}

.reg-list {
  display: flex;
  flex-direction: column;
}

.reg-list ul {
  padding: 20px 0;
  list-style-type: none;
}

.reg-list ul span {
  display: flex;
  padding-bottom: 20px;
}

.reg-list ul span img {
  width: 19px;
  height: 19px;
}

.reg-list li {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #252525;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #252525;
}

#terms {
  font-style: italic;
  font-size: 12px;
  color: #1b9690;
  font-weight: 700;
  margin-left: 25px;
}

.reg-list small {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #252525;
}

.right {
  background: #eff6f6;
  box-shadow: 0px -4px 20px rgba(154, 196, 150, 0.05),
    0px 4px 30px rgba(97, 134, 196, 0.05);
  border-radius: 32px;
  padding: 40px;
}

.reg-form-container {
  background-color: transparent;
}

.form-desc {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #605d62;
  padding-bottom: 50px;
}

.reg-form-container form {
  display: flex;
  flex-direction: column;
}

.form-one {
  gap: 20px;
  width: 100%;
}

.form-one input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  width: 100%;
  outline: none;
  background: #ffffff;
  border: 1px solid #9dcac8;
  border-radius: 50px;
}

.form-one button {
  margin-top: 30px;
}

.form-two {
  width: 100%;
}

.form-two label {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 10px;
}

.form-two select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #9dcac8;
  border-radius: 100px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("./asset/dropdown.svg");
  background-repeat: no-repeat;
  background-position: center right 30px;
}

select:focus {
  outline: none;
}

.form-two .form-select-con{
  margin-bottom: 20px;
}

.form-two input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  border-radius: 50px;
  outline: none;
  background: #ffffff;
  border: 1px solid #9dcac8;
  margin-bottom: 20px;
}

input::placeholder, textarea::placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #afafaf;
}

input, textarea {
  font-size: 20px;
  color: #49484C;
  font-weight: normal;
}

textarea {
  padding: 16px 32px;
  width: 100%;
  height: 120px;
  background: #ffffff;
  border: 1px solid #9dcac8;
  border-radius: 16px;
  resize: none;
  outline: none;
}

.form-two small {
  width: 100%;
  height: 32px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin: 10px auto;
}

.form-two button {
  width: 100%;
}

.available {
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.available input {
  width: 15px;
  height: 15px;
  padding: 2px;
}

.check-left{
  width: 90%;
}

.check-text {
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  color: #1b9690;
}

@media (min-width:600px) and (max-width: 992px) {
  .reg-content {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .reg-content > div {
    width: 100%;
  }

  .form-desc {
    width: 100%;
  }

  input::placeholder, textarea::placeholder {
    font-size: 18px;
  }

  input, textarea {
    font-size: 18px;
  }

  .left {
    padding-right: 0;
  }

  .reg-img-con{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .left img {
    width: 80%;
  }

  #reg-para {
    width: 100%;
  }
}

@media (min-width: 0) and (max-width: 600px) {
  .reg-content-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .reg-content {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .reg-content > div {
    width: 100%;
  }

  .reg-content h3 {
    padding: 10px 0;
  }

  #reg-para {
    font-size: 18px;
    line-height: 32px;
    width: 100%;
  }

  .right {
    padding: 50px 20px;
  }

  .left {
    padding-right: 0;
  }

  .left img {
    width: 100%;
  }

  .form-desc {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    width: 100%;
    padding-bottom: 30px;
  }

  input::placeholder, textarea::placeholder, .css-1jqq78o-placeholder {
    font-size: 16px !important;
  }

  input, textarea {
    font-size: 16px;
  }

  .form-one button {
    margin-top: 30px;
  }

  /* .available {
    display: flex;
    align-items: center;
  } */
}
