.footer-container {
  background-color: #333333;
  color: #f2f9fb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  width: 100%;
}

.footer-link-wrapper {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  width: 300px;
  gap: 10px;
}

.footer-link-items p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  margin: 0;
  padding: 0 0 24px 0;
}

.footer-link-items h4 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 800;
  margin: 0;
  padding: 0 0 24px 0;
}

.footer-link-items input::placeholder {
  color: #5aaaa5;
  font-weight: 700;
  font-size: 16px;
}

.lang {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
}

svg {
  color: #27d6cd;
}

.foot-logo {
  margin-bottom: 24px;
}

.form-container {
  background: #f1fcff;
  border-radius: 100px;
  height: 44px;
  width: 260px;
  padding: 2px;
}

form {
  display: flex;
}

.form-container input {
  background: #f1fcff;
  padding: 0 0 0 10px;
  height: 40px;
  width: 100%;
  border-radius: 100px;
  border: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.form-container button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 !important;
  width: 72px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-icons {
  display: flex;
  justify-content: center;
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.footer-bottom {
  background-color: #252525;
  width: 100%;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #f2f9fb;
  padding: 0;
  margin: 0;
}

.footer-form {
  width: 100%;
  height: 50px;
}

@media (min-width: 0) and (max-width: 992px) {
  .foot-logo {
    margin-bottom: 10px;
  }

  .footer-link-wrapper {
    flex-direction: column;
    height: 100%;
  }

  .footer-link-items {
    margin: 16px 0;
    width: 100%;
  }

  .footer-link-items p {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    padding: 0 0 10px 0;
  }

  .footer-link-items h4 {
    font-weight: 800;
    font-size: 22px;
    line-height: 40px;
    padding: 0 0 10px 0;
  }

  .footer-container button {
    width: 30%;
    padding: 0 30px !important;
  }

  .footer-bottom {
    width: 100%;
  }

  .website-rights {
    font-size: 12px;
  }
}

@media (min-width: 0) and (max-width: 300px) {
  .form-container {
    width: 100%;
  }
}
