.trusted {
  padding-top: 80px;
}


.trusted-img {
  height: 100px;
  width: 150px;
  /* width: 200px; */
  object-fit: contain;
}

.trusted-img2 {
  height: 100px;
  width: 100px;
  /* width: 200px; */
  object-fit: contain;
}

.trusted-img3 {
  height: 100px;
  width: 130px;
  /* width: 200px; */
  object-fit: contain;
}

.trusted-img1 {
  height: 100px;
  width: 200px;
  object-fit: contain;
}

.trusted h5 {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #1b9690;
  margin: 0;
}

.trusted-img-con {
  width: 4000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding-right: 120px;
}

.marquee-container {
  /* width: 1300px !important; */
  width: 1900px !important;
  background: white !important;
}

.marquee {
  /* min-width: 1300px !important; */
  min-width: 1900px !important;
  background: white !important;
}

@media (min-width: 0) and (max-width: 768px) {
  .trusted {
    padding-top: 30px;
  }

  .marquee-container {
    /* width: 1300px !important; */
    width: 1900px !important;
    background: white !important;
  }


  .marquee {
    /* min-width: 1300px !important; */
    min-width: 1900px !important;
    background: white !important;
  }
}

@media (max-width: 475px) {
  .trusted-img {
    width: 100px;
  }

  .trusted-img1 {
    width: 150px;
  }

  .trusted-img-2 {
    width: 90px;
  }
}
