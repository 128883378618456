
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.flex .heading {
  margin-top: 0;
  width: 100%;
}

.flex > div {
  width: 48%;
}

.button-container{
  display: flex;
}

.hero-img-wrapper {
  max-width: 555px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  margin: 0 60px;
}

.hero-des-img{
    display: block;
}

.hero-mobile-img{
    display: none;
}

/* adding css changes 7-16-2024 */
.hero-img-con {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  top: -62.06px;
}

.stack_one {
  z-index: 20;
}

.stack_two {
  z-index: 10;
  position: relative;
  top: 136.94px;
  left: -33.06px;
}

.stack_one-img {
  box-shadow: 0px 4px 30px 0px rgba(0,0,0,0.05);
 filter: saturate(1.38); /* added 7-18-2024 */
} 

/* added 7-18-2024 */
.stack_two-img {
  filter: saturate(1.28);
}

.stack_one-img, .stack_two-img {
  border-radius: 24px;
}

.stack_three {
  position: absolute;
  padding: 8px 16px 8px 16px;
  background-color: #5AAAA5;
  z-index: 30;
  border-radius: 100px;
  bottom: -12px;
  left: -40px;
}

.stack_three-text {
  font-size: 14px;
  font-weight: normal;
}

/* added change 7-18-2024 */
.stack_container {
  position: relative;
  z-index: 20;
}

.stack-four {
  position: absolute;
  z-index: 40;
  bottom: -113px;
  right: 36px; /* added change 7-18-2024 */
  width: 121px;
}

.stack-four-img {
 width: 100%;
 filter: saturate(1.3); /* added 7-18-2024 */
}

/* modified changes 7-18-2024 */
img {
  pointer-events: none;
  user-select: none;
}

.hero-img-mobile {
  display: none;
}



/* modified changes on flex box 7-16-2024 */
@media (min-width: 0) and (max-width: 992px) {
  .flex {
    flex-direction: column;
  }

  .hero .hero-img-con {
    margin-top: 60px;
    padding-bottom: 116px;
  }

  .hero-img-con {
    top: 0;
  }

  .flex > div {
    width: 100%;
  }

  .stack_three {
    bottom: 111px;
    left: 48px;
  }

  .stack-four {
    bottom: -105px; /* added 7-18-2024 */
  }
}

@media (min-width: 0) and (max-width: 600px) {
    .hero-des-img{
        display: none;
    }

    .hero-mobile-img{
        display: block;
    }

    .button-container{
      display: block;
    }


    .stack_three {
      bottom: 113px;
    }

    .hero-img-con {
      display: none;
    }

    .hero-img-mobile {
      display: block;
    }

    .hero-img-mobile img {
      filter: saturate(1.38)
    }

    .hero .hero-img-mobile {
      margin-top: 30px;
    }
}

/* created new media query 7-16-2024 */
@media (min-width: 1000px) and (max-width: 1024px) {
  .stack_one {
    position: relative;
    left: 32px;
  }

  .stack_two {
    left: 0;
  }
}


