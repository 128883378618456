.cost {
  margin: 100px 0 70px 0;
}

.cost .hero-des-img {
  width: 90%;
}

.cost .flex {
  margin-top: 0 !important;
}

/* .cost-des-img {
  width: 100%;
} */

@media (min-width: 768px) and (max-width: 992px) {
  .cost {
    margin: 80px 0 50px 0;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .cost {
    margin: 50px 0 20px 0;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  .cost .hero-img-con {
    margin-top: 0 !important;
    margin-bottom: 40px;
  }

  .cost .hero-des-img {
    width: 100%;
  }

  .cost .sub-heading {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 10px;
  }

  /* added gap of 20px to flex box 7-16-2024 */
  .cost .flex {
    gap: 64px;
  }
}
