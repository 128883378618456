.founders {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  color: #fff;
  margin-top: 40px;
}

.founder {
  border-radius: 23px;
  overflow: hidden;
  height: 377px;
  width: 270px;
  position: relative;
}

.founder img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: relative;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 20px;
  width: 100%;
  position: absolute;
  height: 125px;
  padding: 16px 5px;
  bottom: 0;
}

.details h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.details p {
  font-style: normal;
  margin: 3px 0 10px 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
}

.details.jeph {
  background-color: #1b9690;
}

/* for lilian */
.details.lilian {
  background-color: #1b9690;
}

.details.karen {
  background-color: #fb8f67;
}

.details.udoye {
  background-color: #fb8f67;
}

.details.lloyd {
  background-color: #9067c6;
}

.detail-links {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.founder-icon-link svg {
  color: #fff;
}

.founder-icon-link svg:hover {
  color: yellowgreen;
}

.founder:last-child {
  justify-self: center;
  grid-column: 1 / -1;
}

@media (min-width: 600px) and (max-width: 992px) {
  .founders {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px
  }
}

@media (min-width: 0) and (max-width: 600px) {
  .founders {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px
  }
}
