.form-select-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* css changes on .hire-para 7-18-2024 */
.hire-para {
  font-weight: 800;
  font-size: 18px;
  line-height: 32px;
  color: #252525;
  margin-top: 10px;
}

.select {
  width: 100%;
  position: relative;
}

.css-13cymwt-control {
  width: 100%;
  border-radius: 50px !important;
  background: #ffffff;
  border: 1px solid #9dcac8;
}

.react-country-flag {
  margin-left: 32px;
}

.css-1fdsijx-ValueContainer,
.css-hlgwow {
  padding: 16px 0 16px 32px !important;
}
.flag-select .css-1fdsijx-ValueContainer,
.css-hlgwow {
  padding: 16px 0 16px 12px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  color: #000;
}

.css-13cymwt-control {
  border-color: #9dcac8 !important;
  padding-right: 20px;
}

.css-13cymwt-control:hover {
  border-color: #9dcac8 !important;
}

.css-t3ipsp-control {
  border-color: #9dcac8 !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover {
  border-color: #9dcac8 !important;
}

.css-1jqq78o-placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #afafaf !important;
}

.css-1dimb5e-singleValue,
.css-166bipr-Input {
  font-size: 20px;
  color: #49484c !important;
  font-weight: normal;
}

.css-1nmdiq5-menu {
  display: block !important;
}


.css-tr4s17-option {
  background-color: #9dcac8 !important;
  color: black !important;
}

.css-tr4s17-option:active {
  background-color: #9dcac8 !important;
  color: black !important;
}

.css-d7l1ni-option:active{
  background-color: #9dcac8 !important;
}

.css-10wo9uf-option:active {
  background-color: #9dcac8 !important;
}

select {
  font-size: 20px;
  color: #49484c !important;
  font-weight: normal;
}

.action-select {
  margin-left: 17px;
  cursor: pointer;
}

/* added new css 7-18-2024 */
.hire_list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 20px;
}

.hire_list-text {
  font-weight: 500;
  font-size: 16px;
  color: #252525;
  list-style-image: url("../asset/hire-a-talent/star.svg");
}

.hire_add_text {
  margin-top: 32px;
  font-size: 14px;
  color: #252525;
  font-weight: 400;
  display: block;
}

.reg-img-con img {
  height: auto;
  filter: saturate(1.3);
}

.css-t3ipsp-control {
  padding-right: 20px !important;
}

/* added change 30-7-2024 */
.form_flex-input {
  display: flex;
  align-items: center;
  gap: 14px;
}

/* added new media queries 7-21-2024 */
@media (min-width: 768px) and (max-width: 992px) {
  .reg-img-con img {
    height: 545px;
    object-fit: cover;
  }
}

@media (min-width: 540px) and (max-width: 600px) {
  .reg-img-con img {
    height: 495px;
    object-fit: cover;
  }

  .form_flex-input {
    flex-direction: column;
  }
}

@media (min-width: 0px) and (max-width: 475px) {
  .reg-img-con img {
    height: 384px;
    object-fit: cover;
  }

  .form_flex-input {
    flex-direction: column;
  }
}

/* added a new media query 30-7-2024 */
/* @media (min-width:1000px) and (max-width:1024px) {
  .form_flex-input {
    flex-direction: column;
  }
} */
