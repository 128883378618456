.discover-bg {
  position: relative;
}

.discover-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../asset/discover/backgroundline.svg");
  opacity: 0.1;
  z-index: -1;
}

.three-model-container {
  padding-top: 90px;
  padding-bottom: 90px;
  width: 100%;
  height: 100%;
}

.tm-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tm-title-container h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #0d4a47;
  width: 900px;
}

.tm-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 80px;
}

.tm-flex-container > div {
  width: 45%;
}

.right-tmf img {
  width: 100%;
  height: 100%;
}

.tm-icons-main-con {
  padding: 16px 0;
}

.tm-icons-main-con img {
  margin-bottom: 16px;
}

.tm-icons-main-con p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #252525;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tm-flex-container > div {
    width: 48%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tm-title-container h1 {
    width: 90%;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  .tm-flex-container {
    display: block;
    margin-top: 30px;
  }

  .tm-flex-container > div {
    width: 100%;
  }

  .right-tmf {
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .three-model-container {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .right-tmf {
    width: 100%;
    height: 50vh;
    margin: 0 auto;
  }

  .right-tmf img {
    object-fit: contain;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  .tm-title-container h1 {
    width: 100%
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .three-model-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tm-title-container h1 {
    font-size: 30px;
    line-height: 34px;
  }

  .tm-icons-main-con {
    padding: 16px 0;
  }
}
