.advantage {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin: 0;
}

.title h3 {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #1b9690;
  margin: 0;
  text-align: center;
}

.advantage .body {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 50px;
}

.ad-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 20px;
  width: 326px;
}

.ad-item h3 {
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  color: #0d4a47;
}

.ad-item p {
  width: 294px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #252525;
}

.icon {
  width: 45px;
  height: 45px;
  padding: 10px;
  background: #eff6f6;
  border-radius: 100%;
}

.orange {
  background-color: #f6ebe7;
  color: rgba(248, 135, 82, 0.767);
}

.purple {
  background-color: #ebe7f1;
  color: rgba(172, 13, 172, 0.658);
}

@media screen and (min-width: 0) and (max-width: 992px) {
  .advantage {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
  }

  .title img {
    width: 107px;
    height: 12px;
  }

  .advantage .body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 30px;
  }


  .ad-item {
    margin-bottom: 10px;
    width: 100%;
    padding: 0;
  }

  .ad-item h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 32px;
    margin-top: 0;
  }

  .ad-item p {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }

  .icon {
    isolation: isolate;
    width: 38px;
    height: 38px;
  }
}
