.company-section {
  margin: 100px 0;
}

/* added new css changes 7-19-2024 */
.flex_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.company-section_heading {
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  color: #14706c;
  margin-bottom: 104px;
}

.slider_container {
  width: 56%;
  margin: 0 auto;
  
}

.swiper-slide {
  width: 500px !important;
}

.swiper-scrollbar {
  display: none !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #0d4a47 !important;
  width: 53px !important;
  height: 8px !important;
  border-radius: 100px !important;
}

.swiper-pagination-bullet {
   width: 31px !important;
  background: #DFEBEA !important;
}

.review_container-box {
  width: 500px;
  height: 274.04px;
  background-color: #0d4a47;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review_container-box::after {
  content: "";
  position: absolute;
  width: 44.34px;
  height: 44.34px;
  background-color: #0d4a47;
  bottom: -19px;
  transform: rotate(45deg);
  left: 46px;
}

.review_container-box > q {
  width: 417px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: white;
}

.review_container-info {
  display: flex;
  gap: 16px;
  margin-top: 64px;
  margin-bottom: 80px;
}

.info_image {
  width: 56px;
  height: 56px;
}

.info_image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid black;
}

.info_name {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info_name h5, .info_name p {
  color: black;
}

.info_name h5 {
  font-weight: 800;
  font-size: 20px;
  margin: 0;
}

.info_name p {
  font-weight: 400;
  font-size: 18px;
}

@media (min-width:768px) and (max-width:992px) {
  .slider_container {
    width: 66%;
  }

  .swiper-slide {
    width: 456px !important;
  }

  .review_container-box {
    width: 456px !important;
  }

  .review_container-box > q {
    width: 377px;
  }
}

@media (min-width:0px) and (max-width:600px) {
  .company-section_heading {
    text-align: center;
  }

  .slider_container {
    width: 100%;
  }

  .swiper-slide {
    width: 100% !important;
  }

  .review_container-box {
    width: 100% !important;
    height: 326.04px;
  }

  .review_container-box > q {
    width: 275px;
  }
}

@media (max-width: 475px) {
  .info_name p {
  font-size: 14px;
}
}

