.navbar {
  background: #198a84;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
}

.vect-logo {
  object-fit: cover;
  width: 160px;
  height: auto;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-inline small {
  color: #0d4a47;
  font-size: 12px;
}

.nav-menu button {
  padding: 8px 16px;
  height: 100%;
}

.contact-icon {
  margin-right: 5px;
  color: #fff !important;
}

.nav-item:hover {
  border-radius: 100px;
  color: #198a84;
  background-color: #fff;
  padding: 8px 16px;
  transition: all 0.3s ease;
  margin: 0 10px;
}

.nav-item.active-link {
  border-radius: 100px;
  background-color: #fff;
  padding: 8px 16px;
}

.contact {
  margin: 0 10px !important;
}

.nav-item.active-link .contact-icon {
  color: #198a84 !important;
}

.nav-links.active-link-text {
  color: #198a84 !important;
  font-weight: 600 !important;
}

.nav-links {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
  height: 100%;
}

.nav-links:hover {
  color: #198a84;
  font-weight: 600;
}

.nav-item:hover .nav-links {
  color: #198a84;
  font-weight: 600;
}

.nav-item2 {
  cursor: default;
}

/* .nav-item2 .nav-links {
  color: #fff !important;
  font-weight: 600;
} */

.career_link {
  color: white;
}

 /* modified the css 10/28/2024 */
.career_link:hover {
  border-radius: 100px;
  color: #198a84;
  background-color: #fff;
  padding: 8px 16px;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.nav-item:hover .contact-icon {
  color: #198a84 !important;
}

hr {
  display: none;
}

.fa-bars {
  color: #198a84;
}

.menu-icon {
  display: none;
}

.display{
  display: none;
}

@media screen and (max-width: 1200px) {
  .navbar {
    background: #198a84;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    margin: 0 auto;
  }

  .vect-logo {
    width: 150px;
    height: auto;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top: 50px;
  }

  .display{
    display: block;
  }

  .nav-menu2 {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top: 50px;
  }

  .nav-menu.active {
    background: #198a84;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    color: #fff;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    font-size: 0.8em;
  }

  /* added a new css */
  .career_link {
    font-size: 15px;
  }

  .nav-item:hover {
    border: none;
  }

  .contact {
    margin-left: 0;
    align-self: flex-end;
  }

  .nav-item {
    width: fit-content;
    height: auto;
    margin: 20px auto;
  }

  .nav-item2 {
    width: fit-content;
    height: auto;
    margin: 20px auto;
  }

  .nav-item-btn {
    margin: 20px auto;
  }

  .nav-item:hover .nav-links {
    z-index: 100;
    background: none;
    color: #198a84;
    background-color: transparent;
    transition: all 0.3s ease;
  }

  .menu-icon {
    display: block
  }

  .fa-reg-times-circle {
    color: #fff;
    font-size: 2rem;
    width: 7.77px;
    height: 0.69px;
    border: 1px solid #cdebf5;
    background: #cdebf5;
    transform: rotate(45deg);
  }
}
