.home__bottom-section {
  color: #1b1d33;
  padding: 150px 0;
}

.home__bottom-section-quality {
  color: #1b1d33;
  padding-top: 150px;
}

.home__bottom-section.darkerBg {
  padding: 50px 0 150px 0;
}

.bottom-container {
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
}

.bottom-img-con {
  padding: 100px 0;
}

.bottom-col {
  display: flex;
  align-content: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

#talents {
  padding: 48px;
  width: 906px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(20, 112, 108, 0.05);
  border-radius: 24px;
}

.bottom-col h2 {
  color: #14706c;
  width: 500px;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
}

.headline {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.topline {
  width: 158px;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #1b9690;
  margin-top: 0;
}

.bottom-col p {
  width: 758px;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-top: 20px;
}

#para {
  margin-top: -30px;
  width: 470px;
  height: 64px;
}

/* adding changes here for grid_wrapper class to the img container 7-17-2024 */
.grid_wrapper {
  display: grid;
  grid-template-columns: 442px 1fr;
  gap: 24px;
}

.flex_container {
  display: flex;
  gap: 24px;
}

.flex_col {
  flex-direction: column;
}

.flex_row {
  flex-direction: row;
}

.flex_frame_one {
  display: flex;
  flex-direction: column;
  height: 182.59px;
  justify-content: center;
  align-items: center;
  /* padding: 25px 20px 25px 20px; */
  border-radius: 16px;
  background-color: #cdbce2;
}

.flex_frame_one-title {
  margin-bottom: 43px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex_frame_one-title p {
  font-size: 12px;
  color: #535353;
  font-weight: 400;
  width: auto;
  margin: 0;
  text-align: start;
  line-height: 18px;
}

.flex_frame_one-title h4 {
  font-size: 14px;
  color: black;
  font-weight: 800;
}

.flex_frame_one-icon {
  width: 32px;
  align-self: start;
  margin-left: 20px;
}

.flex_row-img {
  height: 213.03px;
  flex-basis: 50%;
}

.flex_row-img3 {
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.flex_row-div {
  height: 183px;
}

.flex_row-div img {
  width: 100%;
}

.flex_row-div .flex_row-div-img {
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.flex_frame_two {
  display: flex;
  flex-direction: column;
  height: 213.03px;
  justify-content: center;
  align-items: center;
  /* padding: 25px 20px 25px 20px; */
  border-radius: 16px;
  background-color: #f7ccbc;
  flex-basis: 50%;
}

.flex_frame_two h4 {
  font-size: 14px;
  color: black;
  font-weight: 800;
  align-self: flex-start;
  margin-left: 20px;
}

.flex_frame_two-icon {
  margin-top: 115px;
  width: 32px !important;
  align-self: flex-end;
  margin-right: 20px;
}

.flex_width-one {
  flex-basis: 40%;
}

.flex_width-two {
  flex-basis: 60%;
  /* height: 441px; */
}

.flex_width-two img {
  /* height: 100%; */
  filter: saturate(1.29); /* added 7-18-2024 */
}

.flex_height {
  /* height: 441px; */
  height: 419.84px;
}
.flex_height img {
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  filter: saturate(1.32);
}

/* added 7-18-2024 */
.flex_width-one > div > img {
  filter: saturate(1.27);
}

@media screen and (min-width: 600px) and (max-width: 992px) {
  .bottom-img-con {
    padding: 50px 0;
  }

  .home__bottom-section-quality {
    padding-top: 80px;
  }
}

/* add responsiveness to the grid container from 768px -992px  */
@media screen and (min-width: 768px) and (max-width: 992px) {
  .grid_wrapper {
    grid-template-columns: 1fr;
  }

  /* .flex_width-two {
    height: 583.39px;
  } */

  .flex_width-two img {
    width: 100%;
  }

  .flex_height img {
    width: 100%;
  }

  .flex_row-div {
    height: 243.66px;
  }

  .flex_height {
    height: auto;
  }

  .flex_frame_one {
    height: 316.59px;
    align-items: start;
    justify-content: start;
  }

  .flex_frame_one-title {
    margin-top: 25px;
    margin-left: 20px;
  }

  .flex_frame_one-icon {
    margin-top: 100px;
  }
}

@media screen and (min-width: 0) and (max-width: 600px) {
  .bottom-img-con {
    padding: 30px 0 50px 0;
  }

  .home__bottom-section-quality {
    padding-top: 50px;
  }

  /* change grid template columns to 1 7-17-2024 */
  .grid_wrapper {
    grid-template-columns: 1fr;
  }

  .flex_frame_one {
    height: 201.59px;
  }
}

/* add a new media query of max-width 834px and min-width 800px  */
@media (min-width: 800px) and (max-width: 834px) {
  .flex_frame_one {
    height: 335.59px;
  }

  .flex_frame_one-icon {
    margin-top: 119px;
  }

  .flex_row-div {
    height: 254.66px;
  }
}

/* add a new media query of max-width 475px */
@media screen and (max-width: 475px) {
  .flex_wrap {
    flex-wrap: wrap;
  }

  .flex_width-one {
    flex-basis: auto;
  }

  .flex_width-two {
    flex-basis: auto;
    /* height: auto; */
  }

  .flex_frame_two {
    flex-basis: 100%;
  }

  .flex_row-img {
    flex-basis: 100%;
    height: auto;
  }

  .flex_row-div {
    height: auto;
  }

  .flex_height {
    flex-basis: 100% !important;
  }

  .flex_height img {
    width: 100%;
  }

  .flex_frame_one {
    height: 213.02px;
    align-items: start;
    justify-content: start;
  }

  .flex_frame_one-title {
    margin-top: 25px;
    margin-left: 20px;
  }
}

@media screen and (min-width: 0) and (max-width: 960px) {
  .bottom-container {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 0 30px;
  }

  .bottom-col {
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .bottom-col h2 {
    color: #14706c;
    width: 500px;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
  }

  .bottom-col p {
    width: 200%;
    height: 100%;
    margin-top: 10px;
    padding-bottom: 0 !important;
  }

  .headline {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .home__bottom-section {
    color: #1b1d33;
    padding: 50px 0;
  }

  .home__bottom-section.darkerBg {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .home__bottom-section.darkerBg p {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
  }

  #talents {
    padding: 48px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(20, 112, 108, 0.05);
    border-radius: 24px;
  }

  .topline {
    width: 158px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #1b9690;
    margin-top: 0;
  }

  #para {
    width: 310px;
    height: 96px;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1024px) {
  .grid_wrapper {
    grid-template-columns: 1fr;
  }

  .flex_frame_one {
    height: 362.59px;
    align-items: start;
    justify-content: start;
  }

  .flex_frame_one-title {
    margin-top: 25px;
    margin-left: 20px;
  }

  .flex_frame_one-icon {
    margin-top: 155px;
  }
}