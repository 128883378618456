:root {
  --primary: #198a84;
}

a{
  text-decoration: none !important;
}

.btns{
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #1b9690;
  padding: 8px 20px;
  border: 2.5px solid #1b9690;
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 20px 30px;
}

.button-disabled, .button-disabled:hover{
  cursor: not-allowed !important;
  background-color: #d3d3d3 !important; /* Light gray */
    color: #a9a9a9 !important; /* Dark gray */
}

.btn--center {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
}

.btn--mobile {
  text-align: center;
  margin-bottom: 35px;
  border-radius: 100px;
  width: 40%;
  text-decoration: none;
  font-size: 18px;
  background-color: transparent;
  color: #cdebf5;
  padding: 14px 20px;
  border: 2.5px solid #cdebf5;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--center:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #cdebf5;
  color: #198a84;
}

.btn--wide:hover {
  color: #cdebf5;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.spin-btn-container {
  background-color: rgba(27, 150, 144, 0.2);
  cursor: not-allowed;
  border: 2px solid #fff;
}

.ant-spin .ant-spin-dot-item{
  background-color: #198a84;
  width: 12px;
  height: 12px;
}

.green {
  background-color: #198a84;
  color: #fff;
  border: 2px solid #fff;
}

.green:hover {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  color: #198a84;
  border: none;
  transition: all 0.4s ease-out;
}

.white {
  background-color: #fff;
}

.white .button-icon{
  font-size: 24px;
  color: #198a84;
}

.white:hover .button-icon{
  font-size: 24px;
  color: #cdebf5;
}

.white:hover {
  background-color: #198a84;
  color: #cdebf5;
  border: none;
  transition: all 0.4s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.primary {
  background-color: #198a84;
  color: #fff;
  border: none;
}

.green .button-icon{
  font-size: 24px;
  color: #fff;
}

.primary:hover {
  background-color: #fff;
  color: #198a84;
}

.green:hover .button-icon {
  background-color: #fff;
  color: #198a84;
}

@media (max-width: 620px) {
  .btn--mobile {
    background-color: transparent;
    color: #cdebf5;
    border-color: #cdebf5;
    font-size: 12px;
  }
}

@media (min-width: 360px) and (max-width: 500px) {
  .btn--mobile {
    background-color: transparent;
    color: #cdebf5;
    border-color: #cdebf5;
    font-size: 12px;
    width: 40%;
  }

  .white {
    width: 100%;
  }

  .green {
    width: 100%;
  }
}
